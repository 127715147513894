"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Exception = void 0;
function getFirstItem(arr, typeCheck, defaultValue) {
    const firstItem = arr.find(typeCheck);
    return firstItem !== undefined ? firstItem : defaultValue;
}
class Exception extends Error {
    previous = [];
    code;
    #hidden = 0;
    constructor(...args) {
        const reason = getFirstItem(args, it => typeof it == "string");
        const code = getFirstItem(args, it => typeof it == "number");
        const previous = getFirstItem(args, it => it instanceof Error);
        super(reason);
        this.code = code ?? -1;
        if (previous instanceof Exception) {
            if (previous.previous.length >= 30) {
                previous.previous.shift();
                this.#hidden = previous.#hidden + 1;
            }
            previous.previous.push(this);
            this.previous = previous.previous;
        }
        else {
            this.previous.push(previous);
        }
        if (this.previous.length > 0) {
            const previous = this.previous[this.previous.length - 1];
            if (previous && previous.stack) {
                this.stack = (this.stack ?? "") + (previous.stack ?? "");
                previous.stack = "";
            }
        }
    }
    toString() {
        let hiddenMessage = "";
        if (this.#hidden != 0)
            hiddenMessage = `\n... and ${this.#hidden} more.`;
        return `${this.constructor.name} [ERROR ${this.code}]: ${this.message}\n${this.stack}${hiddenMessage}`;
    }
}
exports.Exception = Exception;
