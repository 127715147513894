"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scope = scope;
exports.randString = randString;
const crypto_1 = require("crypto");
function scope(options) {
    try {
        options.try();
    }
    catch (error) {
        options.catch(error);
    }
    finally {
        if (options.finally)
            options.finally();
    }
}
function randString(length = 8, stack = []) {
    return (0, crypto_1.randomBytes)(length).toString('hex').slice(0, length);
}
