"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialApi = void 0;
const client_1 = require("../client");
class MaterialApi extends client_1.SocketClient {
    static #config = {
        uri: "wss://schoolwork.nicksite.top:40801/material",
        auth: "NickSchoolworkMaterial08"
    };
    static #instance;
    static getInstance(options) {
        if (MaterialApi.#instance)
            return MaterialApi.#instance;
        MaterialApi.#instance = new MaterialApi(MaterialApi.#config, options);
        return MaterialApi.#instance;
    }
}
exports.MaterialApi = MaterialApi;
