"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocketClient = void 0;
const socket_io_client_1 = require("socket.io-client");
const t = __importStar(require("io-ts"));
const type_1 = require("./types/type");
const utils_1 = require("./utils");
const PayloadCodec = t.record(t.string, t.union([t.string, t.boolean, t.number]));
// 定义 StandardResponse 类型
const StandardResponseCodec = t.intersection([
    PayloadCodec,
    t.type({
        ray: t.string,
    }),
]);
class SocketClient {
    #rayFallback;
    #callbackError;
    #socket;
    #ray = {};
    constructor(config, options) {
        this.#socket = (0, socket_io_client_1.io)(config.uri, {
            extraHeaders: {
                auth: config.auth,
            },
            reconnection: config.reconnection ?? true,
            reconnectionDelay: config.reconnectionDelay ?? 3000,
            reconnectionAttempts: config.reconnectionAttempts ?? 5
        });
        this.#socket.on("connect", options.connect);
        this.#socket.on("disconnect", options.disconnect);
        this.#socket.on("connect_error", options.connect_error);
        this.#socket.on("response", this.#handle);
        this.#rayFallback = options.unknown_ray;
        this.#callbackError = options.callback_error;
    }
    #getRay(callback) {
        const rand = (0, utils_1.randString)(2);
        if (this.#ray[rand])
            return this.#getRay(callback);
        this.#ray[rand] = callback;
        return rand;
    }
    #handle(msg) {
        const data = type_1.Type.parse(msg, StandardResponseCodec);
        const ray = data.ray, callback = this.#ray[ray];
        callback ? (0, utils_1.scope)({
            try: () => callback(data),
            catch: error => this.#callbackError(error),
            finally: () => delete this.#ray[ray]
        }) : this.#rayFallback(ray);
    }
    emit(event, data, callback) {
        if (!this.#socket.connected)
            return false;
        const ray = this.#getRay(callback);
        this.#socket.emit(event, { ray, ...data });
        return true;
    }
}
exports.SocketClient = SocketClient;
