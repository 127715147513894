"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TypeErrorCode = exports.TypeError = exports.Type = void 0;
const These_1 = require("fp-ts/These");
const PathReporter_1 = require("io-ts/PathReporter");
const error_1 = require("../error");
class Type {
    static parse(data, type) {
        try {
            if (typeof data === 'string') {
                data = JSON.parse(data);
            }
        }
        catch (e) {
            throw new TypeError('Failed to parse JSON', undefined, e);
        }
        const decoded = type.decode(data);
        if ((0, These_1.isLeft)(decoded)) {
            throw new TypeError(`Could not validate data: ${PathReporter_1.PathReporter.report(decoded).join('\n')}`);
        }
        return decoded.right;
    }
}
exports.Type = Type;
class TypeError extends error_1.Exception {
}
exports.TypeError = TypeError;
var TypeErrorCode;
(function (TypeErrorCode) {
    TypeErrorCode[TypeErrorCode["InvalidJson"] = 0] = "InvalidJson";
    TypeErrorCode[TypeErrorCode["IncapableType"] = 1] = "IncapableType";
})(TypeErrorCode || (exports.TypeErrorCode = TypeErrorCode = {}));
